import React from "react";
import Social from "../SocialTwo";
import { NavLink } from "react-router-dom";

const SkillsTwo = () => {
  return (
    <>
      <div className="priyank_ty_section">
        <div className="priyank_ty_skills">
          <div className="container">
            <div className="skills_inner">
              <div
                className="left"
                data-aos="fade-right"
                data-aos-duration="1200"
              >
                <div className="priyank_ty_title">
                  <h3>We deal with all sorts of statistical methods and techniques</h3>
                  <p>Are you concern about the choosing a sample size?
                   Do you want to know whether your hypothesis is really significant?  
                   Do you have trouble interpreting your results? Don't worry ! We are here to assist you . We will turn your research data into clear, easy-to-understand results using the appropriate statistical tool in accordance with your methodology, research goals, and objectives.</p>
                </div>
                <div className="text" style={{marginTop:"1em"}}>
                  <img src="/img/banner2.jpg" style={{width:"100%"}}></img>
                  {/* <p>
                    I am working on a professional, visually sophisticated and
                    technologically proficient, responsive and multi-functional
                    personal portfolio template.
                  </p> */}
                </div>
                {/* <div className="connect-me">
                  <h5>Connect with me...</h5>
                   <div>
                      <Social />
                   </div>
                </div>

                <div className="connect-me">
                  <h5>
                  Mail me...
                  </h5>
                   <div className="mailus">
                      <a href="mailto:priyank.tyagi9045@gmail.com" target="_blank">Priyank.tyagi9045@gmail.com</a>
                   </div>
                </div> */}
              </div>
              {/* End .left */}

              <div
                className="right"
                data-aos="fade-right"
                data-aos-duration="1200"
              >
                <div className="tokyo_progress">
                  <div className="progress_inner">
                    <span>
                      <span className="label">Data Visualization</span>
                      <span className="number">95%</span>
                    </span>
                    <div className="background">
                      <div className="bar">
                        <div
                          className="bar_in"
                          style={{ width: 95 + "%" }}
                        ></div>
                      </div>
                    </div>
                  </div>
                  {/* End .progress_inner */}

                  <div className="progress_inner">
                    <span>
                      <span className="label">Descriptive Data Analysis</span>
                      <span className="number">99%</span>
                    </span>
                    <div className="background">
                      <div className="bar">
                        <div
                          className="bar_in"
                          style={{ width: 99 + "%" }}
                        ></div>
                      </div>
                    </div>
                  </div>
                  {/* End .progress_inner */}
                  <div className="progress_inner">
                    <span>
                      <span className="label">Statistical Modelling</span>
                      <span className="number">90%</span>
                    </span>
                    <div className="background">
                      <div className="bar">
                        <div
                          className="bar_in"
                          style={{ width: 90 + "%" }}
                        ></div>
                      </div>
                    </div>
                  </div>
                  {/* End .progress_inner */}

                  <div className="progress_inner">
                    <span>
                      <span className="label">Regression Analysis</span>
                      <span className="number">88%</span>
                    </span>
                    <div className="background">
                      <div className="bar">
                        <div
                          className="bar_in"
                          style={{ width: 88 + "%" }}
                        ></div>
                      </div>
                    </div>
                  </div>
                  {/* End .progress_inner */}
                  <div className="progress_inner">
                    <span>
                      <span className="label">Sample Size Determination</span>
                      <span className="number">90%</span>
                    </span>
                    <div className="background">
                      <div className="bar">
                        <div
                          className="bar_in"
                          style={{ width: 90 + "%" }}
                        ></div>
                      </div>
                    </div>
                  </div>
                  {/* End .progress_inner */}

                  <div className="progress_inner">
                    <span>
                      <span className="label">Multivariate Analysis</span>
                      <span className="number">85%</span>
                    </span>
                    <div className="background">
                      <div className="bar">
                        <div
                          className="bar_in"
                          style={{ width: 85 + "%" }}
                        ></div>
                      </div>
                    </div>
                  </div>
                  <div className="progress_inner">
                    <span>
                      <span className="label">Structural Equation Modelling</span>
                      <span className="number">80%</span>
                    </span>
                    <div className="background">
                      <div className="bar">
                        <div
                          className="bar_in"
                          style={{ width: 80 + "%" }}
                        ></div>
                      </div>
                    </div>
                  </div>
                  {/* End .progress_inner */}
                  <div className="progress_inner">
                    <span>
                      <span className="label">Parametric & Non-Parametric Significance Tests  </span>
                      <span className="number">90%</span>
                    </span>
                    <div className="background">
                      <div className="bar">
                        <div
                          className="bar_in"
                          style={{ width: 90 + "%" }}
                        ></div>
                      </div>
                    </div>
                  </div>
                  {/* End .progress_inner */}


                    {/* End .progress_inner */}
                    <div className="progress_inner">
                    <span>
                      <span className="label">Exploratory Data Analysis</span>
                      <span className="number">95%</span>
                    </span>
                    <div className="background">
                      <div className="bar">
                        <div
                          className="bar_in"
                          style={{ width: 95 + "%" }}
                        ></div>
                      </div>
                    </div>
                  </div>
                  {/* End .progress_inner */}


                    {/* End .progress_inner */}
                    <div className="progress_inner">
                    <span>
                      <span className="label">Correlation Analysis </span>
                      <span className="number">92%</span>
                    </span>
                    <div className="background">
                      <div className="bar">
                        <div
                          className="bar_in"
                          style={{ width: 92 + "%" }}
                        ></div>
                      </div>
                    </div>
                  </div>
                  {/* End .progress_inner */}
                </div>
              </div>
              {/* End .right */}
            </div>
          </div>
          {/* End .conainer */}
        </div>
      </div>
    </>
  );
};

export default SkillsTwo;
