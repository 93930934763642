import React from "react";
import Preview from "../views/Preview";
// import HomeOne from "../views/all-home-version/HomeOne";
// import HomeTwo from "../views/all-home-version/HomeTwo";
// import HomeThree from "../views/all-home-version/HomeThree";
import HomeFour from "../views/all-home-version/HomeFour";
import NotFound from "../views/NotFound";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import ContactPage from "../views/Contact";
import Blogs from "../views/Blog";
// import BlogDetail from "../views/BlogDetail";
import Privacy from "../views/Privacy";
import Terms from "../views/termsCondition";
// import MediumBlog from "../views/MediumBlog";
const Routes = () => {
  return (
    <>
      <Router>
        <Switch>
          {/* <Route exact path="/" component={Preview} />
          <Route path="/home-one" component={HomeOne} />
          <Route path="/home-two" component={HomeTwo} />
          <Route path="/home-three" component={HomeThree} /> */}
          <Route path="/contact-us" component={ContactPage} />
          <Route path="/" exact component={HomeFour} />
          <Route path="/insights" component={Blogs} />
          {/* <Route path="/products/:slug" component={BlogDetail} /> */}
          <Route path="/privacy-policy" component={Privacy} />
          <Route path="/terms-and-condition" component={Terms} />
          <Route component={NotFound} />
        </Switch>
      </Router>
    </>
  );
};

export default Routes;
