import React from "react";
import resume from '../../assets/quotation.pdf'

const About = () => {
  return (
    <>
      <div className="priyank_ty_section" id="about">
        <div className="priyank_ty_about">
          <div className="container">
            <div className="about_inner">
              <div className="left">
                <div
                  className="image"
                  data-aos="fade-up"
                  data-aos-duration="1200"
                >
                  <img src="/img/placeholders/1-1.jpg" alt="placeholder" />
                  <div
                    className="main"
                    style={{
                      backgroundImage: `url(${process.env.PUBLIC_URL + "img/contactimg3.jpg"
                        })`,borderRadius:"10px"
                    }}
                  ></div>
                </div>

                {/* End image */}
              </div>
              {/* End left */}

              <div className="right">
                <div
                  className="priyank_ty_title"
                  data-aos="fade-up"
                  data-aos-duration="1200"
                >
                  <span>Welcome to STATALYSIS!</span>
                  <h3>Our Team</h3>
                </div>
                <div
                  className="text"
                  data-aos="fade-up"
                  data-aos-duration="1200"
                >
                  <p>
                  At STATALYSIS, we are more than just individuals - we are a dedicated team of statisticians committed to excellence. Collaborating seamlessly, our diverse skill sets and collective experience enable us to provide unparalleled statistical consultation and analysis support to researchers and scholars worldwide.


                 </p>
                 </div>
                 <div
                  className="priyank_ty_title"
                  data-aos="fade-up"
                  data-aos-duration="1200"
                >
                  
                  <h3>Our Mission</h3>
                </div>
                <div
                  className="text"
                  data-aos="fade-up"
                  data-aos-duration="1200"
                >
                  <p>
                  Our mission at STATALYSIS is simple: to deliver timely, accurate, and high-quality statistical analysis and interpretation. With round-the-clock availability and a comprehensive toolkit of statistical methods, we ensure that our clients receive the support they need, whenever they need it.
                  </p>
                  <p>Whether you're embarking on a new research project or seeking assistance with data analysis, STATALYSIS is here to empower you every step of the way. Reach out to us today and discover the difference expertise can make.</p>
                </div>
                {/* <div
                  className="priyank_ty_button"
                  data-aos="fade-up"
                  data-aos-duration="1200"
                >
                  <a href={resume} download>
                  See Quotations
                  </a>
                </div> */}
              </div>
              {/* End right */}
            </div>
          </div>
          {/* End container */}
        </div>
      </div>
    </>
  );
};

export default About;
