import React from "react";

const SocialShare = [
  // { iconName: "linkedin", link: "https://www.linkedin.com/in/amit-singh-nayal-602214147/5" },
  { iconName: "whatsapp", link: "https://wa.me/9258665244" },
  // { iconName: "skype", link: "skype:live:ded487b3f07357f9?chat" },
  {
    iconName: "mail",
    link: "mailto:statalysis@gmail.com",
  },
];
const Social = () => {
  return (
    <div className="share">
      <span>Share:</span>
      <ul className="social">
        {SocialShare.map((val, i) => (
          <li key={i}>
            <a href={`${val.link}`} target="_blank" rel="noreferrer">
              <img
                className="svg"
                src={`/img/svg/social/${val.iconName}.svg`}
                alt="social"
              ></img>
            </a>
          </li>
        ))}
      </ul>

      {/* END social */}
    </div>
  );
};

export default Social;
