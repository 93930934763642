import React from "react";
import { Link } from "react-router-dom";
import Header from "../components/header/Header";
import Contact from "../components/Contact";
import Social from "../components/Social";

const ContactPage = () => {
  return (
    <>
      <main className="home-four">
        <Header />
        <div className="contact-page">
          <div
            className="priyank_ty_talk bg_image_props"
            style={{
              backgroundImage: `url(${
                process.env.PUBLIC_URL + "img/contactimg.jpg"
              })`,
            }}
          >
            <div className="shape">
              <img
                className="svg"
                src="/img/svg/paper.svg"
                alt="partners brand"
              />
            </div>
            <div className="contact-banner-text">
            <h1>Not Everyone is a Statistical Boffin</h1>
            <h5> let us deal with complex data on your behalf</h5>
            </div>
          </div>
        </div>
        
        <div className="priyank_ty_mobalbox_contact">
          <div className="box_inner">
            <div className="description_wrap">
              {/* Start modal content */}
              <div className="title title-contact ">
                <p>Please submit a detailed description of your project. 
                We will provide you a customized project plan to meet your research requirements. 
                  You can also send emails directly to <a href="mailto:statalysis@gmail.com">statalysis@gmail.com</a> for inquiries.</p>
              </div>
              {/* End title */}

              <div className="wrapper">
                <div className="left">
                  <div className="fields">
                    <Contact />
                  </div>
                </div>
                {/* End left */}
                <div className="right">
                  <div className="map_wrap">
                    <figure>
                      <img src={process.env.PUBLIC_URL + "img/wheel2.jpg"}></img>
                    </figure>
                    {/* <Map /> */}
                  </div>
                </div>
                {/* End right */}
              </div>
              {/* End wrapper */}

              <div className="short_info">
                <ul>
                  <li>
                    <div className="list_inner">
                      <img
                        className="svg"
                        src="img/svg/location.svg"
                        alt="location"
                      />
                      <p>Meerut, National Capital Region, India</p>
                    </div>
                  </li>
                  {/* End silgle address */}

                  <li>
                    <div className="list_inner">
                      <img
                        className="svg"
                        src="img/svg/phone.svg"
                        alt="phone"
                      />
                      <p>
                        <a href="tel:+919258665244">+91-9258665244</a>
                      </p>
                    </div>
                  </li>
                  {/* End silgle address */}

                  <li>
                    <div className="list_inner">
                      <img
                        className="svg"
                        src="img/svg/share.svg"
                        alt="share"
                      />
                      <Social />
                    </div>
                  </li>
                  {/* End silgle address */}
                </ul>
              </div>
              {/* End modal conetent */}
            </div>
          </div>
          {/* End box inner */}
        </div>
        {/* End modalbox news */}
      </main>
    </>
  );
};
export default ContactPage;
