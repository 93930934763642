import React, { useState } from "react";
import { Link } from "react-router-dom";
import Modal from "react-modal";
import Social from "../Social";
import moment from "moment";
import { request } from "graphql-request";

Modal.setAppElement("#root");

const NewsTwo = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [isOpen2, setIsOpen2] = useState(false);
  const [isOpen3, setIsOpen3] = useState(false);

  function toggleModalOne() {
    setIsOpen(!isOpen);
  }
  function toggleModalTwo() {
    setIsOpen2(!isOpen2);
  }
  function toggleModalThree() {
    setIsOpen3(!isOpen3);
  }

  const [products, setProducts] = React.useState([]);
  // console.log("ppp")
  // console.log("post",post)
  React.useEffect(() => {
    const fetchProducts = async () => {
      const productData = await request(
        "https://api-ap-south-1.graphcms.com/v2/ckzw8v3vo39w201za8guz2smj/master",
        `
        query MyQuery {
          posts(where: {featuredPost: true}) {
            excerpt
            createdAt
            featuredImage {
              url
            }
            id
            slug
            title
          }
        }
        `
      );

      setProducts(productData);
    
      //
    };

    fetchProducts();
    
  }, []);
  console.log("pro",products)



  return (
    <div className="priyank_ty_section" id="blogs">
      <div className="priyank_ty_news">
        <div className="container">
          <div className="priyank_ty_title">
            <span>Blogs</span>
            <h3>Featured Blogs</h3>
          </div>
          {/* End priyank_ty_title */}
          <div className="news_list">
            <ul>
            {products && products.posts && products.posts.map((item,index)  => {
           return (
              <li data-aos="fade-right" data-aos-duration="1200">
                <div className="list_inner">
                  {/* <div className="image" onClick={toggleModalOne}> */}
                  <div className="image">
                    <img src={item.featuredImage.url} alt="thumb" />
                    <div
                      className="main"
                      style={{
                        backgroundImage: `url(${
                          item.featuredImage.url
                        })`,
                      }}
                    ></div>
                  </div>
                  {/* End image */}

                  <div className="details">
                    <h3 className="title">
                    {item.excerpt}
                    </h3>
                    <p className="priyank_ty_button">
                      <Link to={`/products/${item.slug}`} className="btn">Read More</Link>
                    </p>
                  </div>
                  {/* End details */}

                  
                </div>
              </li>
             
           )})}
            </ul>
          </div>

          <div className="priyank_ty_button priyank_ty_button2">
              <Link to="/blogs" className="btn">Read More Blogs...</Link>
          </div>
        
        </div>
      </div>
    </div>
  );
};

export default NewsTwo;
