import React from "react";
import Header from "../../components/header/Header";
import Slider from "../../components/slider/SliderFour";
import About from "../../components/about/About";
import Portfolio from "../../components/portfolio/PortfolioTwo";
import Skills from "../../components/skills/SkillsTwo";
import Video from "../../components/video/VideoTwo";
import Brand from "../../components/BrandAnimation";
import Testimonial from "../../components/testimonial/TestimonialTwo";
import News from "../../components/news/NewsTwo";
import CallToAction from "../../components/calltoactions/CallToActionTwo";
import Footer from "../../components/footer/Footer";
import quotation from '../../assets/quotation.pdf';
import sample from '../../assets/sample-work.pdf';


const HomeTwo = () => {
  return (
    <div className="home-four">
      <Header />
      {/* End Header Section */}

      <Slider />
      {/* End Slider Section */}

      <About />
      {/* End About Section */}

      {/* <Portfolio /> */}
      {/* End Portfolio Section */}

      {/* <Skills /> */}
      {/* End Skills Section */}

      {/* <Video /> */}
      {/* End Video Section */}

      <div className="priyank_ty_section">
        <div className="priyank_ty_partners">
          <div className="container">
            <div className="partners_inner">
              <Brand />
            </div>
          </div>
        </div>
      </div>
      {/* End priyank_ty_partners */}

      <section className="services-section" id="services">
        <div className="container">
            <div className="priyank_ty_titles"   data-aos="fade-up"
                  data-aos-duration="1200">
                  <h2>Our Services</h2>
            </div>
            <div className="services-section-inner">
               <div className="col-4">
                    <div className="box-col"   data-aos="fade-up"
                  data-aos-duration="1200">
                         <h3><span><img src="/img/service2.svg" alt="partners brand" /></span>Statistical Analysis</h3>
                         <p>Our statistical analysis service offers comprehensive support for researchers with raw data in hand. Our team of experts conducts thorough statistical analyses to ensure transparency and reproducibility of research findings. We meticulously clean, test, verify, and label your raw data, providing you with a solid foundation for your research endeavors.</p>
                         <p style={{marginTop: '15px'}}><b>What We Provide:</b></p>
                         <ul>
                          <li>Labelled Data: We meticulously clean and label your data, ensuring accuracy and reliability.</li>
                          <li>Visualization: Our service includes the creation of tables, graphs, charts, and figures to supplement your research findings, enhancing clarity and understanding.</li>
                          <li>Statistical Analysis: We offer a range of statistical analyses tailored to your research needs, including descriptive statistics, inferential statistics, regression analysis, and more.</li>
                          <li>Interpretation Report: Receive a detailed interpretation report summarizing the results of the statistical analysis, providing insights and implications for your research.</li>
                       
                          
                         </ul>
                         <p style={{marginTop: '15px'}}>With our support, your research findings will be well-organized, credible, and ready for publication. Whether you're conducting academic research, business analysis, or any other endeavor requiring statistical insight, we're here to help.</p>
                    </div>
               </div>
               <div className="col-4">
                  <div className="box-col"   data-aos="fade-up"
                  data-aos-duration="1200">
                     <h3><span><img src="/img/service3.svg" alt="partners brand" /></span>Statistical Consultation</h3>
                     <p>Our Statistical Consultation service offers professional guidance on selecting and implementing the most suitable statistical tools and techniques for your research endeavors. Whether you're conducting academic research, business analysis, or any other project requiring statistical analysis, our expert consultants are here to support you every step of the way.</p>
                     <p style={{marginTop: '15px'}}><b>What We Provide:</b></p>
                     <ul>
                      <li>Expert Guidance: Our consultants provide expert advice on choosing and implementing the appropriate statistical tools and techniques tailored to your research objectives and data.</li>
                      <li>Methodology Evaluation: We thoroughly evaluate your research methodology and findings, ensuring that the statistical analysis aligns with the objectives of your study and produces reliable results.</li>
                      <li>Recommendation Report: Receive a detailed recommendation report that includes comprehensive observations, explanations, and actionable insights based on our assessment of your research. This report will guide you in making informed decisions and improving the quality and credibility of your findings.</li>

                    
                     </ul>
                     <p style={{marginTop: '15px'}}>With our Statistical Consultation service, you gain access to specialized expertise that can enhance the rigor and validity of your research. Let us help you navigate the complexities of statistical analysis, so you can confidently move forward with your project.</p>
                  </div>
                </div>
               <div className="col-4">
                  <div className="box-col"   data-aos="fade-up"
                  data-aos-duration="1200">
                     <h3><span><img src="/img/service1.svg" alt="partners brand" /></span>Survey Sampling Service</h3>
                     <p>Our Survey Sampling Service offers expertise in designing and implementing effective sampling strategies for your research projects. Sampling is a critical component of survey research, and our team of experts is dedicated to helping you obtain representative and reliable data to support your research objectives.</p>
                     <p style={{marginTop: '15px'}}><b>What We Provide:</b></p>
                     <ul>
                      <li>Sampling Design: We collaborate with you to design a sampling strategy that meets the specific requirements of your research project. Whether you're conducting a population survey, market research, or academic study, we tailor our approach to ensure that your sample is representative and yields meaningful insights.</li>
                      <li>Sampling Implementation: We assist you in implementing your sampling plan, utilizing state-of-the-art techniques to select survey respondents. Our goal is to maximize the efficiency of your survey while maintaining the integrity of your data.</li>
                      <li>Sampling Evaluation: After data collection, we evaluate the effectiveness of your sampling strategy, assessing factors such as sample size, representativeness, and potential sources of bias. Our thorough evaluation ensures that your survey results are accurate and reliable.
Recommendation Report: Receive a comprehensive recommendation report summarizing our findings and providing actionable insights for optimizing your sampling strategy in future research endeavors.
</li>
                    
                     </ul>
                     <p style={{marginTop: '15px'}}>With our Survey Sampling Service, you can be confident in the quality and reliability of your survey data. Whether you're conducting a small-scale study or a large-scale research project, we're here to support you every step of the way.</p>
                  </div>
                </div>
                
            </div>
        </div>
      </section>

      {/* <div className="priyank_ty_section" style={{marginBottom:"2em"}}>
        <div className="priyank_ty_partners">
          <div className="container">
            <div className="partners_inner">
              <Brand />
            </div>
          </div>
        </div>
      </div> */}

      <Skills />
      {/* End Skills Section */}

      <section className="hows-section">
        <div className="container">
            <div className="priyank_ty_titles"   data-aos="fade-up"
                  data-aos-duration="1200">
                      <h2>How to avail our services</h2>
                      <figure>
                        <img src="/img/process.png"></img>
                      </figure>
            </div>
            <div className="btn-two-line">
              <div
                  className="priyank_ty_button"
                  data-aos="fade-up"
                  data-aos-duration="1200"
                >
                   <a href={sample} download>
                     Sample Work
                  </a>
                  <a href={quotation} download>
                     See Quotations
                  </a>
                </div>
            </div>
            </div>
      </section>

      

       <div className="priyank_ty_section">
        <div className="priyank_ty_testimonials">
          <div className="container">
            <div className="testimonials_inner">
              <div
                className="left"
                data-aos="fade-right"
                data-aos-duration="1200"
              >
                <div className="priyank_ty_title">
                  <span>Testimonials</span>
                  <h3>What clients say about <br></br> my work...</h3>
                </div>
              </div>

              <div
                className="right"
                data-aos="fade-right"
                data-aos-duration="1200"
              >
                <Testimonial />
              </div>
            </div>
          </div>
        </div>
      </div> 
      {/* End  priyank_ty_testimonials*/}

      {/* <News /> */}
      {/* <MediumBlog></MediumBlog> */}
      {/* End Blog Section */}

      <CallToAction />
      {/* End CallToAction */}

      <Footer />
      {/* End Footer Section */}
    </div>
  );
};

export default HomeTwo;
