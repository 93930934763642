import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Header from "../components/header/Header";
import BlogBox from "../components/blog/BlogBox";
import Categories from "../components/blog/Categories";
import PostWidget from "../components/blog/PostWidget";
import PostCard from "../components/blog/PostCard";
import { getPosts } from "../services";
import CallToAction from "../components/calltoactions/CallToActionTwo";
import Footer from "../components/footer/Footer";
import { request } from "graphql-request";

export default function Blogs({ }) {
  const [blogData, setBlogData] = useState([]);
  const [loader, setLoader] = useState(true);
  const [newsData, setNewsData] = useState([]);
  useEffect(() => {

    fetch(`https://www.googleapis.com/blogger/v3/blogs/5453395891595807152/posts?maxResults=10&fetchImages=true&fetchBodies=true&PageToken&nextPageToken&key=${process.env.REACT_APP_API_KEY}`)
      .then(res => res.json())
      .then(res => {
        setBlogData(res);
        setLoader(false);
        console.log(res);
      })
      .catch(err => console.log(err));

  }, [])

  useEffect(() => {
    const fetchNews = async () => {
      const productData = await request(
        `https://api-ap-south-1.hygraph.com/v2/cl9ttm59g4cvy01td52tn9rfs/master`,
        `
        query NewsUpdates {
          newsAnsUpdates {
            date
            newsTitle
            publishedAt
            id
            file {
              url
            }
            externalLink
          }
        }
        `
      );

      setNewsData(productData.newsAnsUpdates.reverse()
       );

      //
    };

    fetchNews();

  }, []);

  return (
    <>
      <main className="home-four">
        <Header></Header>
        <div className="contact-page blog-page">
          <div
            className="priyank_ty_talk bg_image_props"
            style={{
              backgroundImage: `url(${process.env.PUBLIC_URL + "img/contactimg.jpg"
                })`,
            }}
          >
            <div className="blog-page-head">
              <h1>Insights</h1>
            </div>
            <div className="shape">
              <img
                className="svg"
                src="/img/svg/paper.svg"
                alt="partners brand"
              />
            </div>
          </div>
        </div>

        <section>
          <div className="container">
            <div className="post-page flex">
              <div className="left-sidebar">
                <div>
                  <div className="priyank_ty_titles priyank_ty_titles_gray" data-aos="fade-up"
                  >
                    <h2 style={{ marginBottom: "0.8em" }}>Blogs</h2>
                  </div>
                </div>
                <div className="blog-list">
                  {loader &&
                    <div className="loader">
                      <img src="/img/logo.gif" alt="partners brand" />
                    </div>
                  }
                  <ul>
                    {blogData && blogData.items && blogData.items.map((item) => {
                      return (
                        <li key={item.id} data-aos="fade-up">
                          <a target="_blank" href={item && item.url}>
                            <figure className="img"
                            >
                              <img src={item && item.images && item.images[0] && item.images[0].url}></img>
                            </figure>
                            <div className="blog-heading">
                              <span className="subheading">&#123; {new Date(item && item.published).toDateString()} &#125;</span>
                              <h2 className="mb-4">{item && item.title}</h2>
                            </div>
                          </a>
                        </li>
                      )
                    })}
                  </ul>
                </div>
              </div>

            </div>

            <div>
              <div>
                <div className="priyank_ty_titles priyank_ty_titles_gray" data-aos="fade-up"
                >
                  <h2 style={{ marginBottom: "1em" }}>News & Updates</h2>
                </div>
              </div>

              <div className="news-and-update">
                <div className="s-no top-bar">
                  S.No
                </div>
                <div className="news-title top-bar">
                  Subject
                </div>
                <div className="news-date top-bar">
                  Date of Uploading
                </div>
                {newsData && newsData.map((item) => {
                  return (
                    <div className="news-and-update-inner" key={item.id}>
                      <div className="s-no bottom-bar">

                      </div>
                      <div className="news-title">
                        <p>{ item && item.newsTitle }</p>
                        {item && item.file && item.file.url &&
                          <p><a href={ item && item.file && item.file.url } target="_blank"><img src={process.env.PUBLIC_URL + "img/pdf.png"}></img>Read more</a></p>
                        }
                         {item && item.externalLink &&
                          <p><a href={ item && item.externalLink } target="_blank"><img src={process.env.PUBLIC_URL + "img/link.png"}></img>Read more</a></p>
                        }
                      </div>
                      <div className="news-date">
                      { new Date(item && item.date).toLocaleDateString() } { new Date(item && item.date).toLocaleTimeString()}
                      </div>
                    </div>
                  )
                })}

              </div>
              <div>

              </div>
            </div>
          </div>
        </section>

        <CallToAction />
        {/* End CallToAction */}

        <Footer />
      </main>
    </>
  );
}

// export default Blogs;
