import React from "react";
import { useForm } from "react-hook-form";
import { send } from 'emailjs-com';

const Contact = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const [toSend, setToSend] = React.useState({
    name: '',
    toName: 'Priyank Tyagi',
    email: '',
    mobile:"",
    subject: '',
    message:""
  });

  const [showThankyou, setShowThankyou] = React.useState(false);
  const onSubmit = (data, e) => {
    e.target.reset();
    console.log("Message submited: " + JSON.stringify(data));
    send(
      'service_0na3aqk',
      'template_9wjg9ko',
      toSend,
      'c5iEoxoYcTYx1Vvop'
    )
      .then((response) => {
        console.log('SUCCESS!', response.status, response.text);
        setShowThankyou(true);
        setTimeout(function() {
          window.location.replace('/');
        }, 5000);
      })
      .catch((err) => {
        console.log('FAILED...', err);
      });
  };
  const handleChange = (e) => {
    setToSend({ ...toSend, [e.target.name]: e.target.value });
  };

  return (
    <>
      {showThankyou &&
      <div className="thankyou-message">
          <div>
             <p>Thankyou for your message , <b>Statalysis team</b> will contact you soon...</p>
          </div>
          
      </div>
       }
      <form className="contact_form" onSubmit={handleSubmit(onSubmit)}>
        <div className="first">
          <ul>
            <li>
              <input
                {...register("name", { required: true })}
                type="text"
                placeholder="Your Name"
                value={toSend.name}
                 onChange={handleChange}
              />
              {errors.name && errors.name.type === "required" && (
                <span>Name is required</span>
              )}
            </li>
            {/* End first name field */}

            <li>
              <input
                {...register(
                  "email",
                  {
                    required: "Email is Required",
                    pattern: {
                      value: /\S+@\S+\.\S+/,
                      message: "Entered value does not match email format",
                    },
                  },
                  { required: true }
                )}
                type="email"
                placeholder="Your Email"
                value={toSend.email}
                 onChange={handleChange}
              />
              {errors.email && <span role="alert">{errors.email.message}</span>}
            </li>
            {/* End email name field */}

            <li>
              <input
                {...register("mobile", { required: true })}
                type="text"
                placeholder="Your Mobile Number"
                value={toSend.mobile}
                 onChange={handleChange}
              />
              {errors.mobile && errors.mobile.type === "required" && (
                <span>Mobile Number is required</span>
              )}
            </li>

            <li>
              <input
                {...register("subject", { required: true })}
                type="text"
                placeholder="Subject"
                value={toSend.subject}
                 onChange={handleChange}
              />
              {errors.subject && errors.subject.type === "required" && (
                <span>Subject is required</span>
              )}
            </li>


            <li>
              <textarea
                {...register("message", { required: true })}
                placeholder="Message"
                value={toSend.message}
                 onChange={handleChange}
              ></textarea>
              {errors.message && <span>Message is required.</span>}
            </li>
            {/* End subject  field */}
          </ul>
        </div>

        <div className="tokyo_tm_button">
          <button type="submit" className="white-fill-bg fill-black">
            Send Message
          </button>
        </div>
        {/* End tokyo_tm_button */}
      </form>
      {/* End contact */}
    </>
  );
};

export default Contact;
