import React from "react";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <>
      <div className="priyank_ty_section">
        <div className="priyank_ty_copyright">
          <div className="container">
            <div className="inner">
              <p>
                &copy; {new Date().getFullYear()} by{" "}
                <a
                  href="https://statalysis.com/"
                  target="_blank"
                  rel="noreferrer"
                >
                  Statalysis
                </a>
                . All rights reserved.
              </p>
              <p className="legal-box-foot">
                <ul>
                  <li>
                    <Link to="/privacy-policy">Privacy Policy</Link>
                  </li>
                  <li>
                    <Link to="/terms-and-condition">Terms & Conditions</Link>
                  </li>
                </ul>
              </p>
              <p className="legal-box-foot">
                <ul>
                  <li>
                    Designed & Developed by<a href="https://priyanktyagi.com">Priyank Tyagi</a>
                  </li>
                 
                </ul>
              </p>
            </div>
            {/* End inner */}
          </div>
        </div>
        {/* End priyank_ty_copyright */}
      </div>
    </>
  );
};

export default Footer;
