import React, { useState } from "react";
import Scrollspy from "react-scrollspy";
import Social from "../SocialTwo";
import { NavLink } from "react-router-dom";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";

const Header = () => {
  const [click, setClick] = useState(false);
  const handleClick = () => setClick(!click);
  const [navbar, setNavbar] = useState(false);

  const changeBackground = () => {
    if (window.scrollY >= 80) {
      setNavbar(true);
    } else {
      setNavbar(false);
    }
  };

  window.addEventListener("scroll", changeBackground);

  return (
    <>
      <div className="priyank_ty_topbar">
        <div className={navbar ? "topbar_inner opened" : "topbar_inner"}>
          <div className="logo">
            <NavLink to="/">
              <img src="/img/logo.gif" alt="partners brand" />
            </NavLink>
          </div>
          {/* End logo */}
          <div className="menu">
            <Scrollspy
              className="anchor_nav"
              items={["home", "about", "services", "news", "contact"]}
              currentClassName="current"
              offset={-200}
            >
              <li>
                <a href="/#home">Home</a>
              </li>
              <li>
                <a href="/#about">About</a>
              </li>
              <li>
                <a href="/#services">Services</a>
              </li>
              <li>
                <a href="/insights">Insights</a>
              </li>
              {/* <li>
                <Link to="/blogs">Blogs</Link>
              </li> */}
              <li>
                <a href="/contact-us">Contact</a>
              </li>
              {/* <li>
                <a
                  className="white-fill-bg btn-outline btn_sm"
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://themeforest.net/item/shane-react-personal-portfolio-template/32642263"
                >
                  Buy Now
                </a>
              </li> */}
            </Scrollspy>
          </div>
          {/* End menu */}
        </div>
      </div>
      {/* End priyank_ty_topbar */}

      {/* Start shane mobile menu */}
      <div className="priyank_ty_mobile_menu">
        <div className="topbar_inner">
          <div className="container bigger">
            <div className="topbar_in">
              <div className="logo">
              <NavLink to="/">
              <img src="/img/logo.gif" alt="partners brand" />
            </NavLink>
              </div>
              {/* End logo */}
              <div className="my_trigger" onClick={handleClick}>
                <div
                  className={
                    click
                      ? "hamburger hamburger--collapse-r is-active"
                      : "hamburger"
                  }
                >
                  <div className="hamburger-box">
                    <div className="hamburger-inner"></div>
                  </div>
                </div>
                {/* End hamburger menu */}
              </div>
            </div>
          </div>
        </div>
        <div className={click ? "dropdown active" : "dropdown"}>
          <div className="container">
            <span className="close_menu" onClick={handleClick}>
              close
            </span>
            <div className="dropdown_inner">
              <ul className="anchor_nav">
                <li className="current">
                  <a href="/#home" onClick={handleClick}>
                    Home
                  </a>
                </li>
                <li>
                  <a href="/#about" onClick={handleClick}>
                    About
                  </a>
                </li>
                <li>
                  <a href="#services" onClick={handleClick}>
                    Services
                  </a>
                </li>
                <li>
                  <a href="/insights" onClick={handleClick}>
                  Insights
                  </a>
                </li>
                <li>
                  <a href="/contact-us" onClick={handleClick}>
                    Contact
                  </a>
                </li>
               
              </ul>
              <div className="social-menu">
                <Social />
              </div>
              {/* End social share */}
            </div>
          </div>
          {/* End container */}
        </div>
      </div>
      {/* End shane mobile menu */}
    </>
  );
};

export default Header;
