import React, { useState, useEffect } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { request } from "graphql-request";

export default function TestimonialTwo() {
  var settings = {
    dots: false,
    arrow: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
  };
  const [products, setProducts] = React.useState([]);
  // console.log("ppp")
  // console.log("post",post)
  React.useEffect(() => {
    const fetchProducts = async () => {
      const productData = await request(
        `https://api-ap-south-1.hygraph.com/v2/cl9ttm59g4cvy01td52tn9rfs/master`,
        `
        query TestimonialsSection {
          testimonialsSection {
            createdAt
            id
            name
            profession
            publishedAt
            reviewsBox
            updatedAt
            profileImage {
              url
            }
          }
        }
        `
      );

      setProducts(productData);

      //
    };

    fetchProducts();

  }, []);
  console.log("pro", products.testimonialsSection)
  return (
    <ul>
      <Slider {...settings}>
        {products && products.testimonialsSection && products.testimonialsSection.map((item) => {
          return(
            <li className="item" key={item.id}>
            <div className="texts">
              <p>
                {item && item.reviewsBox}
              </p>
              <div className="author">
                <div className="image">
                  <div
                    className="main"
                    style={{
                      backgroundImage: `url(${item && item.profileImage && item.profileImage.url
                        })`,
                    }}
                  ></div>
                </div>
                <div className="short">
                  <h3 className="author">
                    <span>{item && item.name}</span>
                  </h3>
                  <h3 className="job">
                    <span>{item && item.profession}</span>
                  </h3>
                </div>
              </div>
            </div>
          </li>
          )
        })}
       
       
     
      </Slider>
    </ul>
  );
}
