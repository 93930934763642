import React from "react";

const SocialShare = [
  // {
  //   iconName: "facebook",
  //   link: "https://www.facebook.com/priyank.tyagi.engineer",
  // },
  { iconName: "linkedin", link: "https://www.linkedin.com/in/amit-singh-nayal-602214147/5" },
  { iconName: "whatsapp", link: "https://wa.me/8171924864" },
  // { iconName: "skype", link: "skype:live:ded487b3f07357f9?chat" },
  {
    iconName: "mail",
    link: "mailto:statalysis@gmail.com",
  },
  // { iconName: "dribbble", link: "https://dribbble.com/" },
  // {
  //   iconName: "tik-tok",
  //   link: "https://www.tiktok.com/",
  // },
];
const Social = () => {
  return (
    <>
      <ul className="social social-default position-relative">
        {SocialShare.map((val, i) => (
          <li key={i}>
            <a href={`${val.link}`} target="_blank" rel="noreferrer">
              <img
                className="svg"
                src={`/img/svg/social/${val.iconName}.svg`}
                alt="social"
              ></img>
            </a>
          </li>
        ))}
      </ul>
      {/* END social */}
    </>
  );
};

export default Social;
